
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/agencies/Agencies.vue";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import _ from "lodash";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "Avaluos",
  components: {
    Field,
    Datatable,
  },
  setup() {
    const loading = ref<boolean>(false);
    const keywords = ref<string>("");
    const buscar = ref<string>("");
    const brand = ref<string>("Marca");
    const year = ref<string>("Año");
    const showing = ref<number>(10);
    const estatus = ref<any>("Estatus");
    const params = ref<any>();
    const headerConfig = ref([
      {
        name: "Valuador",
        key: "valuador",
        sortable: true,
      },
      {
        name: "Cliente",
        key: "cliente",
        sortable: true,
      },
      {
        name: "Vehículo",
        key: "vehiculo",
        sortable: true,
      },
      {
        name: "Agencia",
        key: "agencia",
        sortable: true,
      },
      {
        name: "Estatus",
        key: "estatus",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData1 = ref<any>([]);
    const status = ref<any>([{}]);
    const brands = ref<any>([{}]);
    const years = ref<any>([{}]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    const noResults = ref<boolean>(false);

    const getPagination = async (link) => {
      clearData();
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      getAvaluos(urlProd);
    };

    const showingSet = async () => {
      clearData();
      getAvaluos(params.value);
    };

    /**
     * @method clearData
     * @params none
     * @return Clear data of table
     */ 
    const clearData = () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      total.value = 0;
      from.value = 0;
      to.value = 0;
    }

    /**
     * @method searchDebouncer
     * @params none
     * @return appointments 
     */
    const searchDebouncer = _.debounce(()=>{
     searching();
    },500);

    /**
     * @method searching
     * @params word, valuador, tipo, estatus, agencia
     * @return Valuadores list
     */
    const searching = async (report = false ) => {
      let url = "";
      
      if(buscar.value != ""){
        url += "&search="+buscar.value;
      }
      
      if(estatus.value != "Estatus"){ // estatus.value > 0 
        url += "&status="+estatus.value;
      }
      
      if(brand.value != "" && brand.value != "Marca"){
        url += "&brand="+brand.value;
      }

      if(year.value != "" && year.value != "Año"){
        url += "&year="+year.value;
      }

      params.value = url;

      if(report){
        let urlReport = "api/leads/avaluos-agencia?showing="+showing.value;
        urlReport += url += "&report=1";
        const { data } = await axios.get(urlReport);
        let csvContent = "data:text/csv;charset=utf-8," + data;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ListadoAvaluos.csv");
        link.click();
      }else{
        clearData();
        getAvaluos(url);
      }
    }

    /**
     * @method getAvaluos
     * @params url: string
     * @return avaluos list
     */
    const getAvaluos = async (url = "") => {
        let urlOrigin = "api/leads/avaluos-agencia?showing="+showing.value+"&type=1";
        if(url != "" && !url.includes("http")){
            urlOrigin += url;
        }
        if(url != "" && url.includes("http")){
        urlOrigin = url;
        }
        const {data} = await ApiService.get(urlOrigin);
        let avaluos = data.data.data;
        if(avaluos.length > 0){
            avaluos.forEach((val, i) => {
            tableData1.value.push({
                id_avaluo: val.id_avaluo,
                valuador: val.valuador,
                cliente: val.cliente,
                vehiculo: val.vehicle,
                agencia: val.agency,
                estatus: val.status
            });
            });
            links.value = data.data.links;
            total.value = data.data.total;
            from.value = data.data.from;
            to.value = data.data.to;
            noResults.value = false;
        }else{
            noResults.value = true;
        }
    }

    /**
     * @method getStatus
     * @params none
     * @return return list of status
     */
    const getStatus = async () => {
        const {data} = await ApiService.get("api/leads/avaluos-status");
        status.value = data.data;
    };

    /**
     * @method getBrands
     * @params none
     * @return return list of brands
     */
    const getBrands = async () => {
        const {data} = await ApiService.get("api/vehicle-client-brands");
        brands.value = data.data;
    };

    /**
     * @method getYears
     * @params none
     * @return return years's list
     */
    const getYears = async () => {
        const {data} = await ApiService.get("api/vehicle-client-years");
        let dataF = data.data;
        years.value = await orderYears(dataF);
    };

    /**
     * @method orderYears
     * @params array of years
     * @return return ordered array of years
     */
    const orderYears = (data) => {
        let newYearsList:number[] = [];
        data.forEach(anio => {
            let numberAnio:number = parseInt(anio);
            newYearsList.push(numberAnio);
        });
        newYearsList.sort(function(a, b) {
            return a - b;
        });
        return newYearsList;
    };

    onMounted(async () => {
        getStatus();
        getBrands();
        getYears();
        getAvaluos();
    });

    return {
      headerConfig,
      loading,
      keywords,
      buscar,
      tableData1,
      links,
      getPagination,
      total,
      getAvaluos,
      showing,
      showingSet,
      from,
      to,
      noResults,
      searchDebouncer,
      searching,
      clearData,
      status,
      getStatus,
      estatus,
      getBrands,
      brands,
      brand,
      getYears,
      year,
      years,
      orderYears,
      params
    };
  },
});
