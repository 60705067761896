import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = {
  class: "text-start text-gray-400 fw-bold fs-5 text-capitalize gs-0",
  role: "row"
}
const _hoisted_4 = { class: "fs-base text-white" }
const _hoisted_5 = { class: "odd" }
const _hoisted_6 = {
  colspan: "7",
  class: "dataTables_empty"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }
const _hoisted_9 = {
  key: 0,
  class: "dataTables_length",
  id: "kt_customers_table_length"
}
const _hoisted_10 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Field = _resolveComponent("Field")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", {
        class: _normalizeClass([[_ctx.loading && 'overlay overlay-block'], "table align-middle border border-bottom-0 border-1 border-gray-600 fs-base gy-5 dataTable no-footer"]),
        id: "kt_customers_table",
        role: "grid"
      }, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
              return (_openBlock(), _createElementBlock("th", {
                key: i,
                class: _normalizeClass([
                  cell.name && 'min-w-125px',
                  cell.sortable !== false && 'sorting',
                  _ctx.tableHeader.length - 1 === i && 'text-end',
                  _ctx.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}desc` &&
                    'sorting_desc',
                  _ctx.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}asc` &&
                    'sorting_asc',
                ]),
                tabindex: "0",
                rowspan: "1",
                colspan: "1",
                style: {"cursor":"pointer","background-color":"#191f26","color":"white","padding-left":"10px"}
              }, _toDisplayString(cell.name), 3))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_4, [
          (_ctx.getItems.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getItems, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "odd",
                  style: {"border-bottom":"1px solid #393840"}
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: i,
                      class: _normalizeClass({ 'text-end': _ctx.tableHeader.length - 1 === i }),
                      style: {"background-color":"#111518","padding-left":"10px"}
                    }, [
                      _renderSlot(_ctx.$slots, `cell-${cell.key}`, { row: item }, () => [
                        _createTextVNode(_toDisplayString(item[_ctx.prop]), 1)
                      ])
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            : _createCommentVNode("", true),
          _createElementVNode("template", null, [
            _createElementVNode("tr", _hoisted_5, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.emptyTableText), 1)
            ])
          ])
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.enableItemsPerPageDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("label", null, [
                _createVNode(_component_Field, {
                  name: "pages",
                  autocomplete: "off",
                  class: "form-select form-select-solid select2-hidden-accessible pageNumber"
                }, {
                  default: _withCtx(({ value, errorMessage, handleChange }) => [
                    _createVNode(_component_el_select, {
                      name: "pages",
                      value: value,
                      "model-value": value,
                      "onUpdate:modelValue": handleChange,
                      loading: _ctx.loading,
                      class: _normalizeClass([_ctx.pageNumber, "w-75px"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          class: _normalizeClass(_ctx.fs-_ctx.sm),
                          value: 10,
                          selected: true
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("10")
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_el_option, {
                          class: _normalizeClass(_ctx.fs-_ctx.sm),
                          value: 25,
                          selected: true
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("25")
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_el_option, {
                          class: _normalizeClass(_ctx.fs-_ctx.sm),
                          value: 50,
                          selected: true
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("50")
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_el_option, {
                          class: _normalizeClass(_ctx.fs-_ctx.sm),
                          value: 100,
                          selected: true
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("100")
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _: 2
                    }, 1032, ["value", "model-value", "onUpdate:modelValue", "loading", "class"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_el_pagination, {
                  "current-page": _ctx.pagination.page,
                  "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.page) = $event)),
                  onCurrentChange: _ctx.currentPageChange,
                  "page-size": _ctx.pagination.rowsPerPage,
                  layout: "prev, pager, next",
                  total: _ctx.pagination.total,
                  "hide-on-single-page": true,
                  background: ""
                }, null, 8, ["current-page", "onCurrentChange", "page-size", "total"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}